// eslint-disable-next-line no-irregular-whitespace
// handle close fitting room mini​
const handleMessage = (messageObject) => {
  if (!messageObject.isTrusted || !messageObject.origin.includes("virtusize"))
    return;
  if (messageObject.data) {
    const parsedMessage = JSON.parse(messageObject.data);
    if (
      parsedMessage.name &&
      parsedMessage.name === "user-closed-vs-fitting-room-recommend"
    ) {
      document.getElementById("vs-fitting-room-mini").remove();
      window.removeEventListener("message", handleMessage, false);
    }
  }
};

window.addEventListener("message", handleMessage, false);

// load fitting room mini
// replace each of these example values with the actual values
const params = {
  env: "production",
  region: "com",
  language: "ja",
  apiKey: "8ff81a99ec736da0ff867697519d5116427d0f09",
  version: window.innerWidth < 769 ? "mobile" : "desktop",
  productIds: ["CB0043453601"],
};

let parentOriginUrl = `${window.location.protocol}//${window.location.hostname}`;
if (window.location.port) parentOriginUrl += `:${window.location.port}`;

const iFrame = document.createElement("iframe");
iFrame.id = "vs-fitting-room-mini";
iFrame.loading = "lazy";

// Tasks requested: https://app.clickup.com/t/2hjwz9n?comment=930027792
const baseUrl = `https://static.api.virtusize.${params.region}/a/fitting-room-mini/testing/index.html`;
iFrame.src = `${baseUrl}?params=${encodeURIComponent(
  JSON.stringify(params)
)}&parentOrigin=${encodeURIComponent(parentOriginUrl)}`;

iFrame.width = "350px";
iFrame.height = "280px";
iFrame.style.cssText =
  "position: fixed; left: 50%; bottom: 10%; transform: translateX(-50%); border: none; z-index: 2147483647;";

document.body.appendChild(iFrame);
